import request from '@/utils/request';

export function getSiteCode(params) {
  return request({
    url: '/site_code/data',
    method: 'GET',
    params
  });
}
export function ExportGasCode(params) {
  return request({
    url: '/site_code/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function getComplaints(params) {
  return request({
    url: '/complaints/data',
    method: 'get',
    params
  });
}

export function getComplaintDetail(params) {
  return request({
    url: '/complaints/get_detail',
    method: 'POST',
    params
  });
}

export function getComplaintAdd(params) {
  return request({
    url: '/api/complaints',
    method: 'POST',
    params
  });
}

export function getComplaintEdit(params) {
  return request({
    url: '/complaints/edit_form',
    method: 'POST',
    params
  });
}
