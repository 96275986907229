<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.status"
            clearable
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option value="2" label="未处理" />
            <el-option value="1" label="已处理" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #status="{ scope }">
        <span :class="scope.row.status === 0 ? 'text-danger' : 'text-info'">{{
          scope.row.status === 0 ? '未处理' : '已处理'
        }}</span>
      </template>
      <template #actions="{ scope }">
        <el-button
          v-if="scope.row.status === 0"
          size="mini"
          type="primary"
          @click="handleEdit(scope.row)"
        >
          处理
        </el-button>
        <el-button
          v-else
          size="mini"
          type="primary"
          @click="handleEdit(scope.row)"
        >
          查看
        </el-button>
      </template>
    </default-table>

    <el-dialog
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :title="isShowSubmitBtn ? '投诉处理' : '查看'"
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <ele-form
        v-model="formData"
        :form-desc="formDesc"
        :rules="rules"
        labelPosition="right"
        ref="form"
        :span="24"
        :isShowSubmitBtn="isShowSubmitBtn"
        :isShowCancelBtn="isShowSubmitBtn"
        :isShowBackBtn="false"
        :request-fn="submitForm"
        :isShowErrorNotify="false"
        @cancel="handleCancel"
        :form-attrs="{
          size: 'small'
        }"
        :disabled="!isShowSubmitBtn"
      >
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { getComplaints, getComplaintEdit } from '@/api/qr_code';
import { columns } from './columns/complaintRecord';
import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      searchParams: {},
      columns,
      data: [],
      tankerList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },
      visible: false,
      isShowSubmitBtn: true,
      formData: {},
      formDesc: {
        site_name: {
          type: 'text',
          layout: 12,
          label: '加油站：'
        },
        complainant: {
          type: 'text',
          layout: 12,
          label: '投诉人：'
        },
        tel: {
          type: 'text',
          layout: 12,
          label: '联系电话：'
        },
        time: {
          type: 'text',
          layout: 12,
          label: '投诉时间：'
        },
        content: {
          type: 'text',
          label: '投诉内容：'
        },
        remark: {
          type: 'textarea',
          label: '处理结果：'
        }
      },
      rules: {
        remark: [{ required: true, message: '请填写处理结果' }]
      }
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleEdit(e) {
      this.visible = true;
      this.formData = e;
      if (this.formData.status === 0) {
        // 处理
        this.isShowSubmitBtn = true;
        console.log(this.$refs['form']);
        this.$refs['form'] && this.$refs['form'].resetForm();
      } else {
        // 查看
        this.isShowSubmitBtn = false;
      }
    },
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      getComplaints(params).then(res => {
        // console.log(res);
        this.data = [];
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },

    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: this.pagination.pageSize
      });
    },
    handleCancel() {
      console.log('handleCancel');
      this.visible = false;
    },
    submitForm() {
      console.log(this.formData);
      getComplaintEdit({
        id: this.formData.id,
        remark: this.formData.remark
      }).then(res => {
        console.log(res);
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.visible = false;
          this.handleQuery({
            current: this.pagination.current,
            pageSize: this.pagination.pageSize
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-info {
  color: #01adbe;
}

.text-danger {
  color: #f56c6c;
}
</style>
